import gql from 'graphql-tag';
import apolloClient from '../../apollo/apollo';

const state = {
    users: [],
    user: null,
    permissions: JSON.parse(localStorage.getItem('permissions')) ?? [],
    naveMenu: [],
    loggedUser: 'null',
    roles: [],
    token: localStorage.getItem('token') || '',
};

const getters = {
    getPermissions: (state) => state.permissions,
    getAllUsers: (state) => state.users,
    getSingleUser: (state) => state.user,
    getLoggedUser: (state) => state.loggedUser,
    isAuthenticated: (state) => !!state.token,
    getRoles: (state) => state.roles,
};

const mutations = {
    setAllUsers(state, users) {
        state.users = users.slice();
    }, setSingleUser(state, user) {
        state.users = [...state.users, user];

    }, setPermissionsMutation(state, permissions) {
        state.permissions = permissions;
    }, setloggedUser(state, user) {
        state.loggedUser = user
    }, setToken(state, token) {
        state.token = token;
    }, fetchRoles(state, roles) {
        state.roles = roles;
    }, logout(state) {
        state.token = '';
        state.user = null;
        state.permissions = [];
    },
};

const actions = {
    async changePasswordByUserId({commit}, {newPassword, userId}) {

        try {
            const response = await apolloClient.mutate({
                mutation: gql`
          mutation changeUserPassword($newPassword: String!, $userId: ID!) {
            changeUserPassword(newPassword: $newPassword, userId: $userId) {
              id
            }
          }
        `, variables: {
                    newPassword: newPassword, userId: userId
                }, context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },
            });

            if (response.data.changeUserPassword.errors) {
                console.error('Failed to change user:', response.data.changeUserPassword.errors);
                return false;
            } else {
                return true;
            }

            // commit('setSingleUser', response.data.createUser);
        } catch (error) {
            console.error('Failed to create user:', error.message);
        }

    },

    hasOneOfRoles({commit}, roles) {
        return state.permissions.some(permission => roles.includes(permission));
    }, setPermissions({commit}, permissions) {
        commit('setPermissionsMutation', permissions);
    },

    async fetchAllUsers({commit}) {
        try {
            const response = await apolloClient.query({
                query: gql`
          query findAllUsers {
            findAllUsers {
              id
              roleObject{
              id
              name
              }
              email
              contact {
              fullName
                firstName
                lastName
                email
              }
              userType
              role
            }
          }
        `, context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },
            });
            commit('setAllUsers', response.data.findAllUsers);
        } catch (error) {
            console.error("Failed to fetch users:", error);
        }
    }, async fetchSingleUser({commit}, id) {
        try {
            const response = await apolloClient.query({
                query: gql`
          query findOneUserById($id: ID!) {
            findOneUserById(id: $id) {
              id  
              contact {
              fullName
                firstName
                lastName
                email
                contactPhones {
                  phone
                }
              }
              userType
              role
              email
            }
          }
        `, variables: {id: id}, context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },
            });

            if (response.data.errors) {
                alert('Failed to fetch user:', response.data.errors)
                return;
            }


            commit('setSingleUser', response.data.findOneUserById);
        } catch (error) {
            console.error('Failed to fetch user:', error);
        }
    }, async addUser({commit}, user) {
        try {
            const response = await apolloClient.mutate({
                mutation: gql`
          mutation createUser($userInput: UserInput!) {
            createUser(user: $userInput ) {
              id
              email
              userType
              role
              contact {
                firstName
                lastName
                email
              }
            }
          }
        `, variables: {
                    userInput: {
                        email: user.email,
                        password: user.password,
                        role: 'ADMINISTRATOR',
                        roleId: user.roleId,
                        contact: {
                            type: "USER",
                            email: user.email,
                            firstName: user.contact.firstName,
                            lastName: user.contact.lastName,
                        },
                        userType: user.userType,
                        listOfDepartmentId: user.listOfDepartmentId,
                        listOfPhone: user.listOfPhone,
                        listOfAppointmentTypeServiceId: user.listOfAppointmentTypeServiceId
                    }
                }, context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },
            });

            if (response.data.createUser.errors) {

                console.error('Failed to create user:', response.data.createUser.errors);
                alert('Failed to create user:', response.data.createUser.errors)
                return;
            }

            // commit('setSingleUser', response.data.createUser);
        } catch (error) {
            console.error('Failed to create user:', error.message);
        }
    },

    async changeCurrentPassword({commit}, {oldPassword, newPassword}) {

        try {
            const response = await apolloClient.mutate({
                mutation: gql`
                           mutation changeCurrentPassword($oldPassword: String!,$newPassword: String!) {
                             changeCurrentPassword(oldPassword: $oldPassword, newPassword: $newPassword) {
                                id
              
                              }
                             } `, variables: {
                    oldPassword: oldPassword, newPassword: newPassword
                }, context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },
            });

            // commit('setSingleUser', response.data.updateUser);

            if (response.errors) {
                return false;
            }

        } catch (error) {

            console.error('Failed to update user:', error.message);
            return false;
        }

        return true;

    },


    async updateUser({commit}, user) {
        try {
            const response = await apolloClient.mutate({
                mutation: gql`
          mutation updateUser($id: Int!, $user: UserInput!) {
            updateUser(id: $id, user: $user) {
              id
              email
              contact {
                firstName
                lastName
                email
                contactPhones {
                  phone
                }
              }
              userType
              roleId
            }
          }
        `, variables: {
                    id: user.id,
                    user: {
                        isActive: user.isActive,
                        contact: user.contact,
                        email: user.email,
                        password: user.password, // Temporary default password
                        roleId: user.roleId,
                        userType: user.userType,
                        listOfDepartmentId: user.listOfDepartmentId,
                        listOfPhone: user.listOfPhone,
                        listOfAppointmentTypeServiceId: user.listOfAppointmentTypeServiceId,
                        isLocked: user.isLocked,
                        requiredMfa: user.requiredMfa
                    },
                }, context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },
            });

            // commit('setSingleUser', response.data.updateUser);

        } catch (error) {
            console.error('Failed to update user:', error.message);
        }
    },

    async updateDoctorSetting({commit}, doctorSetting) {
        try {
            const response = await apolloClient.mutate({
                mutation: gql`
          mutation updateDoctorSetting($userId: Int!, $doctorSettingInput: DoctorSettingInput!) {
            updateDoctorSetting(userId: $userId, doctorSettingInput: $doctorSettingInput) {
              id
            }
          }
        `, variables: {
                    userId: doctorSetting.userId, doctorSettingInput: {
                        defaultAppointmentTypeId: doctorSetting.defaultAppointmentTypeId,
                        calendarBookingNotifications: doctorSetting.calendarBookingNotifications,
                        notifyBeforeDays: doctorSetting.notifyBeforeDays,
                        cancelAppointmentNotify: doctorSetting.cancelAppointmentNotify,
                        dailyAppointmentEmail: +doctorSetting.dailyAppointmentEmail,
                        showInOnlineBooking: doctorSetting.showInOnlineBooking,

                        listOfUserReference: doctorSetting.listOfUserReference,
                        listOfAvailability: doctorSetting.dayAvialblesGroupByDepartment.map(item => {
                            return {
                                departmentId: item.departmentId, listOfDays: item.items.map(day => {

                                    return {
                                        day: day.day,
                                        isActive: day.isActive == true ? 1 : 0,
                                        availables: day.availables.map(myAvailable => {
                                            return {
                                                fromTime: myAvailable.fromTime, toTime: myAvailable.toTime
                                            }
                                        }),
                                        breaks: day.breaks.map(myBreak => {
                                            return {
                                                fromTime: myBreak.fromTime, toTime: myBreak.toTime
                                            }
                                        })
                                    }

                                })
                            }
                        }),

                        designation: doctorSetting.designation,
                        description: doctorSetting.description,

                    },
                }, context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },
            });

            if (response.data.updateDoctorSetting) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Failed to update doctor setting:', error.message);
            return false;
        }
    },
    async login_otp({commit}, {otp,email}) {
        try {
            const response = await apolloClient.mutate({
                mutation: gql`
          mutation login_top($email: String!, $otp: Int!) {
            login_top(otp: $otp, email: $email) {
                accessToken
                permissions
                otp
                user{
                  id
                  email
                  contact{
                    firstName
                    lastName
                  }
                }
            }
          }
        `, variables: {otp: otp, email: email},
            });

            if (response.errors) {
                return {success: false, message: response.errors[0].message};
            }

            if(response.data.login_top.otp==1){
                return {success: false,otp:true, message: "OTP is required"};
            }
            const accessToken = response.data.login_top.accessToken;
            await localStorage.setItem('accessToken', accessToken);
            await localStorage.setItem('permissions', JSON.stringify(response.data.login_top.permissions));
            await localStorage.setItem('user', JSON.stringify(response.data.login_top.user));

            await commit('setToken', accessToken);
            await commit('setloggedUser', response.data.login_top.user);
            await commit('setPermissionsMutation', response.data.login_top.permissions);

            return {success: true};
        } catch (error) {
            console.error('Login error:', error);
            return {success: false, message: error.message};
        }
    },
    async login({commit}, {password, email}) {
        try {
            const response = await apolloClient.mutate({
                mutation: gql`
          mutation login($email: String!, $password: String!) {
            login(password: $password, email: $email) {
                accessToken
                permissions
                otp
                user{
                  id
                  email
                  contact{
                    firstName
                    lastName
                  }
                }
            }
          }
        `, variables: {password: password, email: email},
            });

            if (response.errors) {
                return {success: false, message: response.errors[0].message};
            }

            if(response.data.login.otp==1){
                return {success: false,otp:true, message: "OTP is required"};
            }
            const accessToken = response.data.login.accessToken;
            await localStorage.setItem('accessToken', accessToken);
            await localStorage.setItem('permissions', JSON.stringify(response.data.login.permissions));
            await localStorage.setItem('user', JSON.stringify(response.data.login.user));

            await commit('setToken', accessToken);
            await commit('setloggedUser', response.data.login.user);
            await commit('setPermissionsMutation', response.data.login.permissions);

            return {success: true};
        } catch (error) {
            console.error('Login error:', error);
            return {success: false, message: error.message};
        }
    },
    logout({commit}) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('permissions');
        localStorage.removeItem('user');
        localStorage.removeItem('vuex');
        commit('logout');
    },


};

export default {
    state, getters, mutations, actions,
};
