<template>
  <v-app>
    <v-main>
      <Header v-if="$route.path == '/'" :title="$t('name')"></Header>
      <notifications
          width="40%"
          animation-name="v-fade"
          group="failmessages"
          position="bottom right"
      >
        <template slot="body" slot-scope="props">
          <v-alert class="  px-0 py-0 mx-3 FailMessages">
            <v-layout   style="width:100% !important;justify-content: space-between;height:100%;align-items:center">

              <span class="" style="margin: auto 18px"> {{ $t(props.item.text) }}</span>
              <v-btn
                  text
                  small
                  @click="props.close"
                  class="customizedBtn cloeicon px-2"
                  style="margin: 14px"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-layout>
          </v-alert>
        </template>
      </notifications>
      <notifications
          width="40%"
          group="successmesages"
          position="bottom right"
      >
        <template slot="body" slot-scope="props">
          <v-alert class=" mx-3   px-0 py-0 SuccessMesages">
            <v-layout   style="width:100% !important;justify-content: space-between;height:100%;align-items:center">
              <span style="margin: auto 18px"> {{ $t(props.item.text) }}</span>
              <v-btn
                  text
                  small
                  @click="props.close"
                  class="customizedBtn cloeicon px-2"
                  style="margin:12px"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-layout>
          </v-alert>
        </template>
      </notifications>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { AbilityBuilder, Ability } from '@casl/ability';
import Header from "@/components/Header.vue";


export default {
  name: "App",
  components: {Header},

  created() {
    // this.$ability = this.$root.$ability;
  }

};
</script>

<style>
.v-application--wrap {
  min-height: initial;
}

header.px-5.py-5.v-sheet.theme--dark.elevation-0.v-toolbar.v-app-bar.v-app-bar--fixed {
  padding: 0 !important;
  display: flex;
}

.v-progress-circular.loading-indicator.v-progress-circular--visible.v-progress-circular--indeterminate {
    height: 110vh !important;
    width: 100% !important;
    -webkit-backdrop-filter: blur(2.5px);
    backdrop-filter: blur(2.5px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
}

.v-progress-circular > svg {
    width: 70px;
    height: 70px;
    color: #156967;
}

.txt-field .v-input__slot {
    background-color: white !important;
    /* border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important; */
}

.login-icon::before{
    border: 2px solid dimgray !important;
    border-radius: 100% !important;
    padding: 7px !important;
}
</style>
